<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1">Avaimenhaltijat</div>
    <small>
      Avaimenhaltijat ovat vuokralaisia, jotka ovat merkattu vain avaimenhaltijoiksia. Jos
      avaimenhaltija merkataan vuokrasopimukselle päävuokralaiseksi, avaimenhaltijan tila muuttuu
      vuokralaiseksi vuokrasopumuksen allekirjoittamisen jälkeen.
    </small>
    <v-card class="mt-2">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-btn
          color="info"
          class="mb-2"
          @click="
            addKeyHolderDialog = true;
            edit = false;
            keyHolder = {
              name: '',
              phone: '',
              email: '',
              comments: '',
              isOnlyKeyHolder: true,
              relatedContracts: [],
              removedRelatedContracts: [],
              contract: [],
            };
          "
          >Uusi avaimenhaltija</v-btn
        >
      </v-card-title>

      <v-container>
        <v-row dense>
          <v-col md="6" cols="12">
            <v-text-field
              v-model="search"
              label="Asiakasnumero, nimi, email tai puhelin..."
              single-line
              hide-details
              append-icon="mdi-magnify"
              solo
              @keyup.enter="
                getDataFromApi();
                relatedContractSearch = '';
              "
              @click:append="
                getDataFromApi();
                relatedContractSearch = '';
              "
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="10" sm="6" md="4" lg="3" xl="2">
            <v-text-field
              v-model="relatedContractSearch"
              style="font-size: 13px; font-weight: 400"
              label="Sopimuksen numero"
              dense
              outlined
              append-icon="mdi-magnify"
              @keyup.enter="searchByName('relatedContract')"
              @click:append="searchByName('relatedContract')"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" xl="2">
            <label>Hae avaimen mukaan</label>
            <v-radio-group
              v-model="hasKey"
              :mandatory="true"
              style="margin-top: 0px"
              @change="getDataFromApi()"
            >
              <v-radio label="Kaikki" value="all"></v-radio>
              <v-radio label="Ei avainta" value="noKey"></v-radio>
              <v-radio label="Avain" value="key"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-container>

      <v-data-table
        :headers="headers"
        :items="keyHolders"
        :footer-props="globalValues.footerProps"
        :items-per-page="getDefaultTableRows()"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalLength"
      >
        <!-- ClientId -->
        <template #[`item.clientId`]="{ item }">
          <span class="font-weight-bold"># {{ item.clientId }}</span>
        </template>

        <!-- Keyholder / Overview link -->
        <template #[`item.name`]="{ item }">
          <router-link style="text-decoration: none" :to="`/tenant/overview/${item._id}`">
            <span>
              {{ item.name }}
            </span>
          </router-link>

          <v-icon
            size="15"
            style="margin-bottom: 3px; margin-left: 3px"
            @click="openTenantInfoDialog(item)"
            >mdi-eye-outline</v-icon
          >
        </template>

        <!-- Own Contracts -->
        <template #[`item.contract`]="{ item }">
          <span>{{ item.contract.length == 0 ? "Ei sopimuksia" : "" }}</span>

          <span v-for="(contract, index) in item.contract" :key="index + 'a'">
            <router-link
              style="text-decoration: none"
              :to="`/rental-contract/overview/${contract._id}`"
            >
              <span :class="['font-weight-bold', getContractStateColor(contract.state)]">
                {{ contract.contractNumber }}
              </span>
            </router-link>

            <span v-if="index != item.contract.length - 1"> | </span>
          </span>
        </template>

        <!-- Related Contracts -->
        <template #[`item.relatedContracts`]="{ item }">
          <span>{{ item.relatedContracts.length == 0 ? "Ei sopimuksia" : "" }}</span>

          <span v-for="(contract, index) in item.relatedContracts" :key="index + 'a'">
            <router-link
              style="text-decoration: none"
              :to="`/rental-contract/overview/${contract._id}`"
              ><span :class="['font-weight-bold', getContractStateColor(contract.state)]">{{
                contract.contractNumber
              }}</span></router-link
            ><span style="margin: 0px" v-if="index != item.relatedContracts.length - 1"> | </span>
          </span>
        </template>

        <!-- removedRelatedContracts Contracts -->
        <template #[`item.removedRelatedContracts`]="{ item }">
          <span>{{ item.removedRelatedContracts.length == 0 ? "Ei sopimuksia" : "" }}</span>

          <span v-for="(contract, index) in item.removedRelatedContracts" :key="index + 'a'">
            <router-link
              style="text-decoration: none"
              :to="`/rental-contract/overview/${contract._id}`"
              ><span
                :class="['font-weight-bold', getContractStateColor(contract.state)]"
                style="
                  border-bottom: 2px solid rgb(248, 139, 139);
                  padding: 1px 2px 1px 2px;
                  border-radius: 2px;
                "
                >{{ contract.contractNumber }}</span
              ></router-link
            ><span style="margin: 0px" v-if="index != item.removedRelatedContracts.length - 1">
              |
            </span>
          </span>
        </template>

        <!-- Keys -->
        <template #[`item.keys`]="{ item }">
          <router-link
            v-if="item.keys.length == 0"
            style="text-decoration: none"
            :to="`/tenant/overview/${item._id}?tab=5`"
            ><span :class="['font-weight-bold', 'error--text']">Lisää avain</span>
          </router-link>
          <span
            v-for="(key, index) in item.keys"
            :key="index + 'b'"
            class="font-weight-bold success--text"
          >
            {{ key.code }}
            <span v-if="index != item.keys.length - 1">|</span>
          </span>
        </template>

        <!-- Actions -->
        <template #[`item.actions`]="{ item }">
          <div class="action-grid-container">
            <v-icon
              title="Muokkaa"
              small
              @click="
                edit = true;
                addKeyHolderDialog = true;
                keyHolder = { ...item };
              "
              >mdi-pencil</v-icon
            >

            <v-icon title="Poista" small @click="inActivateItem(item)">mdi-delete</v-icon>
          </div>
        </template>
        <template #no-data>
          <h2>Ei avaimenhaltijoita</h2>
        </template>
      </v-data-table>
    </v-card>

    <!-- // Keyholder dialog -->
    <key-holder v-model="addKeyHolderDialog" :edit="edit"></key-holder>

    <!-- Tenant info dialog -->
    <tenant-info-dialog v-model="tenantInfoDialog" sm="12" md="6" lg="6" />
  </div>
</template>

<script>
import mixins from "../../mixins/mixins";
import KeyHolder from "../../components/Keys/KeyHolder";
import { mapActions, mapState, mapMutations } from "vuex";
import globalValues from "@/configs/globalValues";
import TenantInfoDialog from "@/components/Tenant/TenantInfoDialog";

export default {
  title: "Avaimenhaltijat",

  components: {
    KeyHolder,
    TenantInfoDialog,
  },

  mixins: [mixins],

  data: () => ({
    loading: true,
    options: {},
    edit: false,
    search: "",
    relatedContractSearch: "",
    tenantInfoDialog: false,
    hasKey: "all",
    addKeyHolderDialog: false,
    headers: [
      { text: "Asiakasnumero", value: "clientId" },
      { text: "Nimi", value: "name" },
      { text: "Email", value: "email" },
      { text: "Puhelin", value: "phone", sortable: false },
      { text: "Aktiivinen sopimuksella", value: "relatedContracts" },
      { text: "Poistettu sopimuksella", value: "removedRelatedContracts" },
      { text: "Päävuokralainen", value: "contract" },
      { text: "Avaimet", value: "keys", sortable: false },
      {
        text: "Toiminnot",
        value: "actions",
        sortable: false,
      },
    ],
    condominium: {},
    condominiums: [],
    globalValues,
  }),

  computed: {
    ...mapState("keyholder", ["keyHolders", "totalLength"]),
    ...mapState("browsehistory", [
      "dataTablePage",
      "getPreviousPage",
      "itemsPerPage",
      "mainSearchValue",
      "searchField",
      "searchValue",
    ]),

    keyHolder: {
      get() {
        return this.$store.state.keyholder.keyHolder;
      },
      set(val) {
        this.$store.state.keyholder.keyHolder = val;
      },
    },
  },

  watch: {
    options: {
      deep: true,
      handler() {
        if (this.getPreviousPage) {
          if (this.mainSearchValue != "") this.search = this.mainSearchValue;
          if (this.searchField == "relatedContract") this.relatedContractSearch = this.searchValue;

          this.options.page = this.dataTablePage;
          this.options.itemsPerPage = this.itemsPerPage;
        }

        this.saveTableOptions({ ...this.options });

        if (this.relatedContractSearch != "") {
          this.searchByName("relatedContract");
        } else {
          this.getDataFromApi();
        }
      },
    },

    totalLength() {
      this.options.page = 1;
    },
  },

  methods: {
    ...mapActions("keyholder", ["getKeyHolders", "inActivateKeyHolder"]),
    ...mapMutations("browsehistory", ["saveTableOptions", "saveSearchTerm", "saveMainSearchTerm"]),
    ...mapMutations("tenant", ["setCurrentTenant"]),

    async getDataFromApi() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      // Save main search term
      this.saveMainSearchTerm(this.search);

      let search = this.search.trim().toLowerCase();

      let url = `/api/v1/tenant/getAll?isOnlyKeyHolder=true&populateAll=true&sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&itemsPerPage=${itemsPerPage}&searchByKey=${this.hasKey}&search=${search}`;

      await this.getKeyHolders(url);

      this.loading = false;
    },

    async searchByName(name) {
      try {
        this.loading = true;
        let { page, itemsPerPage, sortBy, sortDesc } = this.options;
        let search;
        let additionalSearch = this.search.trim().toLowerCase();
        let url;

        // Save main search term
        this.saveMainSearchTerm(this.search);

        if (name === "relatedContract") {
          this.saveSearchTerm({ field: "relatedContract", val: this.relatedContractSearch });
          search = this.relatedContractSearch.trim().toLowerCase();

          url = `/api/v1/tenant/getAll?isOnlyKeyHolder=true&populateAll=true&sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&itemsPerPage=${itemsPerPage}&relatedContractSearch=${search}&searchByKey=${this.hasKey}&additionalSearch=${additionalSearch}`;
        }

        await this.getKeyHolders(url);

        this.loading = false;
      } catch (err) {
        this.showPopup(err, "error");
      }
    },

    openTenantInfoDialog(item) {
      this.setCurrentTenant(item);
      this.tenantInfoDialog = true;
    },

    async inActivateItem(item) {
      if (confirm("Haluatko varmasti poistaa avaimenhaltijan: " + item.name)) {
        this.inActivateKeyHolder(item._id);
      }
    },
  },
};
</script>

<style scoped></style>
