var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-3 min-w-0 w-full"},[_c('div',{staticClass:"display-1"},[_vm._v("Avaimenhaltijat")]),_c('small',[_vm._v(" Avaimenhaltijat ovat vuokralaisia, jotka ovat merkattu vain avaimenhaltijoiksia. Jos avaimenhaltija merkataan vuokrasopimukselle päävuokralaiseksi, avaimenhaltijan tila muuttuu vuokralaiseksi vuokrasopumuksen allekirjoittamisen jälkeen. ")]),_c('v-card',{staticClass:"mt-2"},[_c('v-card-title',[_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"info"},on:{"click":function($event){_vm.addKeyHolderDialog = true;
          _vm.edit = false;
          _vm.keyHolder = {
            name: '',
            phone: '',
            email: '',
            comments: '',
            isOnlyKeyHolder: true,
            relatedContracts: [],
            removedRelatedContracts: [],
            contract: [],
          };}}},[_vm._v("Uusi avaimenhaltija")])],1),_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('v-text-field',{attrs:{"label":"Asiakasnumero, nimi, email tai puhelin...","single-line":"","hide-details":"","append-icon":"mdi-magnify","solo":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.getDataFromApi();
              _vm.relatedContractSearch = '';},"click:append":function($event){_vm.getDataFromApi();
              _vm.relatedContractSearch = '';}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"10","sm":"6","md":"4","lg":"3","xl":"2"}},[_c('v-text-field',{staticStyle:{"font-size":"13px","font-weight":"400"},attrs:{"label":"Sopimuksen numero","dense":"","outlined":"","append-icon":"mdi-magnify"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchByName('relatedContract')},"click:append":function($event){return _vm.searchByName('relatedContract')}},model:{value:(_vm.relatedContractSearch),callback:function ($$v) {_vm.relatedContractSearch=$$v},expression:"relatedContractSearch"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"2"}},[_c('label',[_vm._v("Hae avaimen mukaan")]),_c('v-radio-group',{staticStyle:{"margin-top":"0px"},attrs:{"mandatory":true},on:{"change":function($event){return _vm.getDataFromApi()}},model:{value:(_vm.hasKey),callback:function ($$v) {_vm.hasKey=$$v},expression:"hasKey"}},[_c('v-radio',{attrs:{"label":"Kaikki","value":"all"}}),_c('v-radio',{attrs:{"label":"Ei avainta","value":"noKey"}}),_c('v-radio',{attrs:{"label":"Avain","value":"key"}})],1)],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.keyHolders,"footer-props":_vm.globalValues.footerProps,"items-per-page":_vm.getDefaultTableRows(),"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalLength},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.clientId",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v("# "+_vm._s(item.clientId))])]}},{key:"item.name",fn:function(ref){
              var item = ref.item;
return [_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":("/tenant/overview/" + (item._id))}},[_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])]),_c('v-icon',{staticStyle:{"margin-bottom":"3px","margin-left":"3px"},attrs:{"size":"15"},on:{"click":function($event){return _vm.openTenantInfoDialog(item)}}},[_vm._v("mdi-eye-outline")])]}},{key:"item.contract",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.contract.length == 0 ? "Ei sopimuksia" : ""))]),_vm._l((item.contract),function(contract,index){return _c('span',{key:index + 'a'},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":("/rental-contract/overview/" + (contract._id))}},[_c('span',{class:['font-weight-bold', _vm.getContractStateColor(contract.state)]},[_vm._v(" "+_vm._s(contract.contractNumber)+" ")])]),(index != item.contract.length - 1)?_c('span',[_vm._v(" | ")]):_vm._e()],1)})]}},{key:"item.relatedContracts",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.relatedContracts.length == 0 ? "Ei sopimuksia" : ""))]),_vm._l((item.relatedContracts),function(contract,index){return _c('span',{key:index + 'a'},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":("/rental-contract/overview/" + (contract._id))}},[_c('span',{class:['font-weight-bold', _vm.getContractStateColor(contract.state)]},[_vm._v(_vm._s(contract.contractNumber))])]),(index != item.relatedContracts.length - 1)?_c('span',{staticStyle:{"margin":"0px"}},[_vm._v(" | ")]):_vm._e()],1)})]}},{key:"item.removedRelatedContracts",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.removedRelatedContracts.length == 0 ? "Ei sopimuksia" : ""))]),_vm._l((item.removedRelatedContracts),function(contract,index){return _c('span',{key:index + 'a'},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":("/rental-contract/overview/" + (contract._id))}},[_c('span',{class:['font-weight-bold', _vm.getContractStateColor(contract.state)],staticStyle:{"border-bottom":"2px solid rgb(248, 139, 139)","padding":"1px 2px 1px 2px","border-radius":"2px"}},[_vm._v(_vm._s(contract.contractNumber))])]),(index != item.removedRelatedContracts.length - 1)?_c('span',{staticStyle:{"margin":"0px"}},[_vm._v(" | ")]):_vm._e()],1)})]}},{key:"item.keys",fn:function(ref){
              var item = ref.item;
return [(item.keys.length == 0)?_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":("/tenant/overview/" + (item._id) + "?tab=5")}},[_c('span',{class:['font-weight-bold', 'error--text']},[_vm._v("Lisää avain")])]):_vm._e(),_vm._l((item.keys),function(key,index){return _c('span',{key:index + 'b',staticClass:"font-weight-bold success--text"},[_vm._v(" "+_vm._s(key.code)+" "),(index != item.keys.length - 1)?_c('span',[_vm._v("|")]):_vm._e()])})]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"action-grid-container"},[_c('v-icon',{attrs:{"title":"Muokkaa","small":""},on:{"click":function($event){_vm.edit = true;
              _vm.addKeyHolderDialog = true;
              _vm.keyHolder = Object.assign({}, item);}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"title":"Poista","small":""},on:{"click":function($event){return _vm.inActivateItem(item)}}},[_vm._v("mdi-delete")])],1)]}},{key:"no-data",fn:function(){return [_c('h2',[_vm._v("Ei avaimenhaltijoita")])]},proxy:true}],null,true)})],1),_c('key-holder',{attrs:{"edit":_vm.edit},model:{value:(_vm.addKeyHolderDialog),callback:function ($$v) {_vm.addKeyHolderDialog=$$v},expression:"addKeyHolderDialog"}}),_c('tenant-info-dialog',{attrs:{"sm":"12","md":"6","lg":"6"},model:{value:(_vm.tenantInfoDialog),callback:function ($$v) {_vm.tenantInfoDialog=$$v},expression:"tenantInfoDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }